
import { db } from '@/firebase/firebaseDB'
import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"

const users = db.collection('users');

export const loginGoogle = (data) => {
    let provider = new firebase.auth.GoogleAuthProvider();
    firebase     
       .auth()
       .signInWithPopup(provider)
       .then(async(result) => {
           let token = result.credential.accessToken;
           let user = result.user;
           // User that was authenticated
           const userCurrent = firebase.auth().currentUser;

           if (userCurrent) {
               await user.updateProfile({
               displayName: [userCurrent.displayName,data.phone].join(",") ,
               });
               createUser(data)
           } 

       })
       .catch((err) => {
            // This will give you all the information needed to further debug any errors
       });
   
  };




export const logOutGoogle = () => {
    firebase
    .auth()
    .signOut()
  };

  export const isLogin = async (callback) => {
     await firebase.auth().onAuthStateChanged(function(user) {
      callback(user)
    })
  }


export const getCategory = (callback) => {
    return category.onSnapshot((snapshot) => {
      const list = snapshot.docs.map((doc) => doc.data());
      callback(list);
    });
  };


  export const register = async (data) => {
    await firebase.auth().createUserWithEmailAndPassword(data.email, data.password);    
    const user = firebase.auth().currentUser;

    if (user) {
        await user.updateProfile({
        displayName: [data.name,data.phone].join(",") ,
        });
        createUser(data,user.uid)
    } 

  }
  
  export const login = async (data) => {
    await firebase.auth().signInWithEmailAndPassword(data.email, data.password)
  }

  export const createUser = async (data,id) => {
   

    data.id=id
    return users.doc(id).set(data);
}

export const logOut= async()=>{
    await firebase.signOut();
 }
   

 export const getUserById = (callback, id) => {

  

    return users.doc(`${id}`).onSnapshot((snapshot) => {
      const list = snapshot.data()
  
      callback(list);
    });
 
};


export const updateUser = (id, data) => {

    return users.doc(id).update(data);


};