import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import 'firebase/compat/auth';


// Get a Firestore instance

const config = {
  apiKey: "AIzaSyAIX4KsXGW7GtnGLDQ4kguYr2l-M7LUya8",
  authDomain: "magicamentecreativos.firebaseapp.com",
  projectId: "magicamentecreativos",
  storageBucket: "magicamentecreativos.appspot.com",
  messagingSenderId: "703133007746",
  appId: "1:703133007746:web:62fa23b1428c263e19d99b",
  measurementId: "G-LZH74N799K"
};
const firebaseApp = firebase.initializeApp(config);
export const auth=firebaseApp.auth();
export const db = firebaseApp.firestore()