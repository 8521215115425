/*
=========================================================
Muse - Vue Ant Design Dashboard - v1.0.0
=========================================================

Product Page: https://www.creative-tim.com/product/vue-ant-design-dashboard
Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by Creative Tim

=========================================================
The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. 
*/

import Vue from 'vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import App from './App.vue'
import DefaultLayout from './layouts/Default.vue'
import DashboardLayout from './layouts/Dashboard.vue'
import DashboardRTLLayout from './layouts/DashboardRTL.vue'
import router from './router'
// import './plugins/click-away'
import  date from './utils/filters';
import './scss/app.scss';
import moment from "moment";
const CUSTOM_LOCALE = "es-RD";
Vue.use(Antd);

Vue.config.productionTip = false

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
Vue.component("layout-dashboard-rtl", DashboardRTLLayout);
Vue.filter('date', (value) => {
  if (value == undefined || value == "") return "-";

  let result = moment(value)
    .locale(CUSTOM_LOCALE)
    .format("DD/MMM/YYYY")
    .replace(/ 0(?:0)?/, " ");

  return result;
});
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')