import moment from "moment";
import Vue from "vue";

const CUSTOM_LOCALE = "es-PR";

Vue.filter("empty", (value) => {
  return value || "-";
});


Vue.filter("formatDate", (value, format = null) => {
  if (value) {
    return moment(value)
      .locale("es-PR")
      .format(format == null ? "DD/MMM/YYYY" : format);
  }
});


export const date = (value) => {
  if (value == undefined || value == "") return "-";

  let result = moment(value)
    .locale(CUSTOM_LOCALE)
    .format("DD/MMM/YYYY")
    .replace(/ 0(?:0)?/, " ");

  return result;
};

export const dateTime = (value) => {
  if (!value) return "-";
  
  return `${date(value)} ${hour(value)}`
};

export const hour = (value) => {
  if (value == undefined || value == "") return "-";

  let result = moment(value).locale(CUSTOM_LOCALE).format("hh:mm a");

  return result;
};


