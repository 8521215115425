import { db } from '@/firebase/firebaseDB'
const permissions = db.collection('permissions');


export const getPermissionByCategoryEmail = (callback,email) => {  
    return permissions.where('email', "==", email).onSnapshot((snapshot) => {
      const item = snapshot.docs.map((doc) => doc.data());
   
      if(item<=0){
        callback(false);
        return;
      }
      callback(true);
    });
}; 